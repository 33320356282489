.form-control.is-invalid {
  border: 1px solid red;
}
.invalid-feedback{
  color: red;
}
.color-red, .c-red{
  color: red;
}

.pointer{
  cursor:pointer;
}

.aliceblue{
  background-color: aliceblue;
}

.c-blue{
  color: blue;
}
.c-green{
  color: green;
}
.c-yellow{
  color: rgb(179, 123, 12);
}

.c-black{
  color: black;
}

.w-30 {width: 30%;}
.w-40 {width: 40%;}
.w-45 {width: 45%;}
.w-50 {width: 50%;}
.w-60 {width: 60%;}
.w-70 {width: 70%;}
.w-80 {width: 80%;}
.w-90 {width: 90%;}
.w-100 {width: 100%;}
@media (min-width: 1200px){
  .container {
      width: 80%!important;
  }
}

.app-space-around{
  display: flex;
  align-items: center;
  justify-content: space-around;
}


@media (min-width: 320px) and (max-width: 767px) {
  .search-container {
    padding: 30px 0 0;
  }
  .advance-search{
    margin-top: 1px;
  }
}

.p-0{
  padding: 0px;
}
.p-2{
  padding: 2px;
}
.p-3{
  padding: 2px;
}
.p-4{
  padding: 4px;
}
.p-5{
  padding: 5px;
}


/**
** Mes reservations
**/

.info_total_payement{
  display: flex;
  justify-content: space-between;
}
.info_total_payement>div:first-child{
  font-weight: bold;
  font-size: 18px;
}
.info_total_payement>div:last-child{
  color: #91af26;
  font-weight: bold;
  font-size: 18px;
}


.info_total_payement_rs{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  margin-top: 5px;
  padding-top: 5px;
}
.info_total_payement_rs>div:first-child{
  font-weight: bold;
  font-size: 20px;

}
.info_total_payement_rs>div:last-child{
  color: #24a241;
  font-weight: bold;
  font-size: 20px;
}
.expire_rs{
  font-size: 20px;
  text-decoration: underline;
}
.mt-30{
  margin-top: 30px;
}
.panel-etat{
  display: flex;
  justify-content: space-around;
  color: #24a241;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  border-radius: 7px;
  border-bottom: 1px solid #ddd;
}

.featured-ribbon-rs {
  height: 100px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 100px;
  z-index: 1;
}
.featured-ribbon-rs span {
  box-shadow: 0 3px 12px -7px rgba(0, 0, 0, 1);
  color: #fff;
  display: block;
  font: 600 12px / 30px "Source Sans Pro", sans-serif;
  position: absolute;
  right: -35px;
  text-align: center;
  text-transform: uppercase;
  top: 13px;
  transform: rotate(45deg);
  width: 125px;
  background: #00BF8F none repeat scroll 0 0;
}

.inherit{
  position:inherit;
}

.label_payement{
  width: 33%;
  text-align: center;
  cursor: pointer;
}
.label_payement:hover{
  background-color: aliceblue;
}

.chiffre_ttc{
  font-weight: bold;
  font-size: 18px;
  color: var(--bs-yellow)
}

.img_logo_numero{
  position: absolute;
  width: 65px;
  right: 0px;
  top: 45px;
}

.relative{
  position: relative;
}

.icon_lock{
  position: absolute;
  right: 12px;
  top: 47px;
  font-size: 23px;
}
.icon_calendar{
  position: absolute;
  right: 12px;
  top: 49px;
  font-size: 20px;
}

.bt-ddd{
  border-top: 1px solid #ddd;
  padding-top:20px;
  padding-bottom: 14px;
}
.td-u{
  text-decoration: underline;
}

.mt-20{
  margin-top: 20px;
}

.info-payement-block{
  background: #cdecfd;
  padding: 25px;
}

.panel-payement-method{
  background: #fffdde;
  padding: 35px;
  margin-bottom: 15px;
  margin-top: -12px;
}


.btn-reserver {
  width: 180px!important;
  background: rgb(0, 191, 143)!important;
  color: rgb(255, 255, 255)!important;
  padding: 0!important;
  margin-top: 2px!important;
  height: 45px!important;
  line-height: 40px!important;
}