/*  Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .both-on-mobile{
        clear: both;
        width: 100%;
    }

 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .both-on-mobile{
        clear: both;
    }

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    

 }